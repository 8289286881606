#password-input {
  padding: 12px 15px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: transparent;
  color: white;
  width: 100%;
  font-size: 17px;
  height: 44px;
}

#password-input:focus {
  outline: 2px solid #48A3FF;
}

#password-submit {
  box-shadow: 0 0 10px #2d81d4;
  background-color: #2d81d4;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 0.05em;
  font-family: sans-serif;
}

#password-submit:hover {
  background-color: #3479d3;
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 500;
}

.big-password-container {
  width:100%;
  height:100%;
  position: absolute;
  overflow: hidden;
}

#title {
  font-size: 15vh;
  text-shadow: 0 0 13px white;
  margin: 0;
  text-align: center;
}

#hook {
  font-size: 3vh;
  text-shadow: 0 0 9px white;
  text-align: center;
}