html {
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Comfortaa', 'Raleway', 'Nunito';
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(18, 18, 18);
}

h1, h2, h3, h4, h5, h6, p {
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

p {
  font-family: 'Comfortaa', 'Raleway', 'Nunito';
}

a {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #48A3FF;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(65, 146, 226);
}

::-webkit-scrollbar-track {
  background-color: rgb(10, 10, 10);
}