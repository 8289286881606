nav {
  position: sticky;
  z-index: 100;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3%;
  height: 70px;
  width: 100%;
  transition: 350ms;
  background-color: #2d81d4;
}

.center-group {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between the centered elements */
}

.spacer {
  flex: 1;
}

.right-group {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 2vw;
  margin-left: -2vw;
}

.nav-item {
  text-decoration: none;
  font-family: Comfortaa;
  padding: 5px 5px 5px 5px;
  font-size: 16px;
  color: rgb(230, 230, 230);
  transition: 300ms;
  font-weight: bold;
}

.nav-item:hover {
  color: #a2b9cc;
}