#homepage-container {
  overflow: hidden;
}

#activities-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  left: 18%;
  margin-top: 14px;
  float: left;
}

#activities-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40vw;
  margin-bottom: -15px;
}

#activity-name, #activity-duration {
  font-size: 28px;
  letter-spacing: 0.025em;
  margin-left: 25px;
  margin-right: 25px;
}

#accomplishments-header {
  font-size: 40px;
  letter-spacing: 0.065em;
  margin-bottom: 5px;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid;
}

#clear-fix::after {
  content: "";
  clear: both;
  display: table;
}

/* FORM STYLES */
#form-container {
  position: fixed;
  top: 50%; /* Position it halfway down the viewport */
  right: 15%; /* Optional: Position it 20px from the right */
  transform: translateY(-50%); /* Adjust its position to center it vertically */
  display: flex;
  flex-direction: column;
  width: 300px; /* Set a fixed width if needed */
  z-index: 50;
}

input[type=text] {
  width: 100%;
  padding: 12px 15px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color:transparent;
  color: white;
}

input[type=text]:focus {
  outline: 1px solid #48A3FF;
  box-shadow: 0 0 8px #48A3FF;
}

input[type=submit] {
  width: 100%;
  background-color: #2d81d4;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 0 10px #2d81d4;
}

input[type=submit]:hover {
  background-color: #3479d3;
}

#add-entry-text {
  margin-bottom: 5px;
}

#activity-scroll-control {
  height: 56vh;
  overflow-y: auto;
}

#activity-scroll-control::-webkit-scrollbar {
  width: 7px;
}