.time-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  align-items: center;
  height: 13vh;
}

.backButton {
  color: white;
  transition: 200ms;
}

.backButton:hover {
  color: #a2c4e6;
  cursor: pointer;
}

.flex-container {
  display: flex;
  padding: 0 70px 0 70px;
  align-items: center;
  height: calc(100% - 26vh)
}

.charts {
  flex-grow: 1.5;
  flex-basis: 0;
  width: 100%;
  height: 100%;
}

#pi-chart-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

#pi-chart {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.empty {
  flex-grow: 1;
  flex-basis: 0;
}

.time-frames {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  gap: 8px;
}

.time-button {
  height: 40px;
  font-family: Comfortaa;
  background-color: rgb(18, 18, 18, 0);
  border: none;
  color: #8d959e;
  border-radius: 10px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  transition: 100ms;
}

.time-button:hover {
  background-color: #2e2e2e;
  cursor: pointer;
}

.selected {
  height: 40px;
  font-family: Comfortaa;
  background-color: #2d81d4;
  box-shadow: 0 0 8px #2d81d4;
  color: white;
  border: none;
  border-radius: 10px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  transition: 100ms;
}

.charts h3 {
  text-align: center;
  color: #cc0000;
}

html {
  scroll-snap-type: y mandatory;
  scroll-padding-top: 70px;
  scroll-behavior: smooth;
}

section {
  background-image: linear-gradient(rgb(18, 18, 18), rgb(25, 25, 25));
  height: calc(100vh - 70px);
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.material-symbols-outlined {
  transform: translateY(1.5px);
  text-align: center;
}

/* Heat Map */
