.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40vw;
  gap: -1px;
}

#activity, #duration {
  font-size: 16px;
  padding-right: 25px;
  padding-left: 25px;
}

#trash {
  visibility: hidden;
  position: absolute;
  left: -3px;
  transform: translateY(-5px);
  cursor: pointer;
}

.row-container:hover #trash {
  visibility: visible;
}